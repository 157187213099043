import restApi from '@infrastructure/api/restApi'

export const name = 'medicalConclusion'

export const stageName = 'medConcStage'

export const groupName = 'medConcStgGroup'

export const recommendationName = 'medConcStgGrRecommendation'

export const todoName = 'medConcStgGrRecTodo'

export const whenName = 'medConcStgGrRecTodoWhen'

export const intervalName = 'medConcStgGrRecTodoInterval'

export const getList = () => restApi.get('/medical-conclusions')

export const getRecommendationById = (id) =>
  restApi.get(`/medical-conclusions/stages/groups/recommendations/${id}`)

export const getDiagnosisArticle = (conclusionId, articleId) =>
  restApi.get(
    `/medical-conclusions/${conclusionId}/diagnosis-articles/${articleId}`
  )

export const getDetailById = (id) => restApi.get(`/medical-conclusions/${id}`)

export const getStageDetail = (conclusionId, stageId) =>
  restApi.get(`/medical-conclusions/${conclusionId}/stages/${stageId}`)

export const getStagePreviewById = (id) =>
  restApi.get(`/medical-conclusions/stages/${id}/preview`)

export const viewRecommendationById = (id) =>
  restApi.patch(`/medical-conclusions/stages/groups/recommendations/${id}/view`)

export const toggleFavoredRecommendationById = (id) =>
  restApi.patch(
    `/medical-conclusions/stages/groups/recommendations/${id}/toggle-favored`
  )

export const getGroupPreviewById = (id) =>
  restApi.get(`/medical-conclusions/stages/groups/${id}/preview`)

const commonStatusMap = {
  terminated: 'terminated',
  active: 'active',
  pending: 'pending'
}

export const todoStatusMap = {
  started: 'started',
  finished: 'finished',
  unStarted: 'unStarted',
  ...commonStatusMap
}

const medicalConclusionRepository = {
  name,
  stageName,
  groupName,
  recommendationName,
  todoName,
  whenName,
  intervalName,
  viewRecommendationById,
  toggleFavoredRecommendationById,
  todoStatusMap
}

export default medicalConclusionRepository
